<template>
    <div class="l-padded">
        <h3 class="t-title">
            {{ $t('shared.assets') }}

            ({{ trackers.length }})
        </h3>

        <div class="l-padded-y panels">
            <div>
                <div class="form-label">
                    {{ $t('byBattery') }}
                </div>

                <ApexChart
                    type="donut"
                    width="100%"
                    height="300"
                    :options="chartByBatteryOptions"
                    :series="chartByBatterySeries"
                />
            </div>

            <div>
                <div class="form-label">
                    {{ $t('byActivity') }}
                </div>

                <ApexChart
                    type="donut"
                    width="100%"
                    height="300"
                    :options="chartByActiveOptions"
                    :series="chartByActiveSeries"
                />
            </div>

            <div>
                <div class="form-label">
                    {{ $t('byType') }}
                </div>

                <div class="type-card-row">
                    <AssetTypeCountCard
                        v-for="(count, assetType) in assetsByType()"
                        :key="`asset-card-${assetType}`"
                        :icon="assetTypeIcons[assetType]"
                        :count="count"
                    />
                </div>
            </div>
        </div>

        <h3 class="t-title">
            {{ $t('latestUpdates') }}
        </h3>

        <div class="table-wrapper">
            <MeasurementsUpdatesTable />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ApexChart from 'vue-apexcharts'
import moment from 'moment'

import AssetTypeCountCard from '../AssetTypeCountCard'
import MeasurementsUpdatesTable from '../MeasurementsUpdatesTable'

const batteryCritical = 5
const batteryWarning = 10

export default {
    name: 'DashboardOverviewView',
    components: {
        ApexChart,
        AssetTypeCountCard,
        MeasurementsUpdatesTable,
    },
    data() {
        return {
            chartByActiveSeries: [],
            chartByActiveOptions: {
                labels: [this.$t('active'), this.$t('inactive')],
                colors: [process.env.VUE_APP_COLOR_PRIMARY, '#aaa'],
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    label: this.$t('total'),
                                    color: '#333',
                                },
                            },
                        },
                    },
                },
                chart: {
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            const measurementQuery = [
                                `online<86400`,
                                'offline>86400',
                            ]

                            this.$router.push({
                                name: 'dashboardAssets',
                                query: {
                                    column: [
                                        'identifier',
                                        'location',
                                        'current_locations',
                                        'last_contact',
                                    ],
                                    measurement:
                                        measurementQuery[config.dataPointIndex],
                                },
                            })
                        },
                    },
                },
                dataLabels: {
                    dropShadow: {
                        enabled: false,
                    },
                    formatter: (value, opts) => {
                        return opts.w.config.series[opts.seriesIndex]
                    },
                },
                fill: {
                    opacity: 0.9,
                },
                legend: {
                    position: 'bottom',
                },
                states: {
                    active: {
                        filter: {
                            type: 'none',
                        },
                    },
                },
            },
            chartByBatterySeries: [],
            chartByBatteryOptions: {
                labels: [
                    this.$t('shared.measurementOptions.battery_status.ok'),
                    `${this.$t(
                        'shared.measurementOptions.battery_status.warning'
                    )} (${batteryWarning}%)`,
                    `${this.$t(
                        'shared.measurementOptions.battery_status.critical'
                    )} (${batteryCritical}%)`,
                ],
                colors: ['#41b883', '#fdae61', '#d32f2f'],
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    label: this.$t('total'),
                                    color: '#333',
                                },
                            },
                        },
                    },
                },
                chart: {
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            const measurementQuery = [
                                `battery>${batteryWarning}`,
                                [
                                    `battery>${batteryCritical}`,
                                    `battery<${batteryWarning + 1}`,
                                ],
                                `battery<${batteryCritical + 1}`,
                            ]

                            this.$router.push({
                                name: 'dashboardAssets',
                                query: {
                                    column: [
                                        'identifier',
                                        'location',
                                        'current_locations',
                                        'last_contact',
                                        'battery',
                                    ],
                                    measurement:
                                        measurementQuery[config.dataPointIndex],
                                },
                            })
                        },
                    },
                },
                dataLabels: {
                    dropShadow: {
                        enabled: false,
                    },
                    formatter: (_, opts) => {
                        return opts.w.config.series[opts.seriesIndex]
                    },
                },
                fill: {
                    opacity: 0.9,
                },
                legend: {
                    position: 'bottom',
                },
                states: {
                    active: {
                        filter: {
                            type: 'none',
                        },
                    },
                },
            },
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapGetters('tracker', ['assetTypeIcons', 'assetsByType']),
    },
    mounted() {
        this.chartByActiveSeries = this.getChartByActiveSeries()
        this.chartByBatterySeries = this.getChartByBatterySeries()
    },
    methods: {
        getChartByActiveSeries() {
            const activeTimestamp = moment()
                .subtract(1, 'day')
                .toJSON()
            let activeCount = 0
            let inactiveCount = 0

            this.trackers.forEach(tracker => {
                if (tracker.last_message_timestamp >= activeTimestamp) {
                    activeCount++
                } else {
                    inactiveCount++
                }
            })

            return [activeCount, inactiveCount]
        },
        getChartByBatterySeries() {
            let okCount = 0
            let warningCount = 0
            let criticalCount = 0

            this.trackers.forEach(tracker => {
                const value = tracker.asset_details.sensor_data?.battery?.value
                if (value > batteryWarning) {
                    okCount++
                } else if (value > batteryCritical) {
                    warningCount++
                } else if (value <= batteryCritical) {
                    criticalCount++
                }
            })

            return [okCount, warningCount, criticalCount]
        },
    },
}
</script>

<i18n>
{
    "en": {
        "active": "Active",
        "byActivity": "Active within 24h",
        "byBattery": "By battery value",
        "byType": "By type",
        "inactive": "Inactive",
        "latestUpdates": "Latest updates",
        "leftLocation": "Left location",
        "locations": "Locations",
        "lowBattery": "Low battery",
        "total": "Total"
    },
    "de": {
        "active": "Aktiv",
        "byActivity": "Aktiv innerhalb von 24h",
        "byBattery": "Nach Batteriezustand",
        "byType": "Nach Typ",
        "inactive": "Inaktiv",
        "latestUpdates": "Neueste Updates",
        "leftLocation": "Standort verlassen",
        "locations": "Standorte",
        "lowBattery": "Niedriger Batteriestand",
        "total": "Total"
    },
    "fr": {
        "active": "Actif",
        "byActivity": "Actif dans les 24h",
        "byBattery": "Par valeur de la batterie",
        "byType": "Par type",
        "inactive": "Inactif",
        "latestUpdates": "Dernières mises à jour",
        "leftLocation": "Quitter l'emplacement",
        "locations": "Emplacements",
        "lowBattery": "Batterie faible",
        "total": "Total"
    },
    "it": {
        "active": "Attivo",
        "byActivity": "Attivo entro 24 ore",
        "byBattery": "Per valore della batteria",
        "byType": "Per tipo",
        "inactive": "Inattivo",
        "latestUpdates": "Ultimi aggiornamenti",
        "leftLocation": "Fuori locazione",
        "locations": "Locazione",
        "lowBattery": "Basso Batteria",
        "total": "Totalmente"
    }
}
</i18n>

<style lang="scss" scoped>
.panels {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;

    & > div {
        flex-grow: 1;
        flex-basis: 0;
        padding: 1rem;
        min-width: 300px;
        border: $style-border;
        border-radius: 8px;
    }

    .form-label {
        text-align: center;
        font-weight: 700;
    }
}

.type-card-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -4px;
    padding: 8px 0 16px;

    div {
        margin: 8px 16px 8px 0;
    }
}

.table-wrapper {
    overflow-x: auto;
    margin-right: -1rem;
    margin-left: -1rem;

    & > * {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}
</style>
